import { motion } from 'framer-motion'
import { useState } from 'react'
import { toast } from 'sonner'

import { sendContactRequest } from '../../utils/request'
import { isValidEmail } from '../../utils/util'
import ContactModal from '../ContactModal'
import SuccessModal from './SuccessModal'

export default function Contact() {
    const [companyName, setCompanyName] = useState('')
    const [industry, setIndustry] = useState('')
    const [employeeCount, setEmployeeCount] = useState('')
    const [position, setPosition] = useState('')
    const [email, setEmail] = useState('')
    const [loading, setLoading] = useState(false)
    const [isButtonDisabled, setIsButtonDisabled] = useState(false)
    const [openSuccess, setOpenSuccess] = useState(false)

    const handleKeyDown = (e) => {
        if(e.key === 'Enter') handleContactRequest()
    }

    const handleContactRequest = async () => {
        if(!companyName) return toast.error('Компанийн нэр хоосон байна')
        if(!industry) return toast.error('Үйл ажиллагааны чиглэл хоосон байна')
        if(!employeeCount) return toast.error('Ажилчдын тоо хоосон байна')
        if(!position) return toast.error('Таны албан тушаал хоосон байна')
        if(!email) return toast.error('Имэйл хаяг хоосон байна')
        if(!isValidEmail(email)) return toast.error('Имэйл хаяг буруу байна')
        setLoading(true)
        try {
            const res = await sendContactRequest(companyName, industry, employeeCount, position, email)
            if(!res) throw new Error('Бүртгэлийн форумыг илгээхэд алдаа гарлаа')
            toast.success('Бүртгэлийн форум амжилттай илгээгдлээ')
            setIsButtonDisabled(true)
            setOpenSuccess(true)
        } catch (error) {
            toast.error('Бүртгэлийн форумыг илгээхэд алдаа гарлаа')
        } finally {
            setLoading(false)
            setIsButtonDisabled(false)
        }
    }

    return (
        <div className='' id="registerForm">
            <ContactModal open={openSuccess} setOpen={setOpenSuccess}>
                <SuccessModal setOpen={setOpenSuccess}/>
            </ContactModal>

            <div className="px-5 lg:px-[50px] max-w-[1440px] mx-auto pt-[150px] sm:pt-[180px] bg-white">
                <div className="flex flex-col items-center justify-center text-center">
                    <motion.h1 initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.2 }} viewport={{ once: true }} className="text-3xl sm:text-[38px] text-primary leading-7 sm:leading-[48px]">Бүртгэлийн форум</motion.h1>
                </div>
                <motion.div initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.6 }} viewport={{ once: true }} className="flex flex-col gap-[45px] max-w-3xl mx-auto w-full mt-[50px]">
                    <div className="flex flex-col justify-center items-center gap-[15px]">
                        <div className="w-full sm:w-1/2">
                            <span className="text-base sm:text-lg text-primary/75 leading-6">
                                Компанийн нэр
                            </span>
                            <div className="mt-2">
                                <input id="companyName" name="companyName" type="text" value={companyName} onChange={(e) => setCompanyName(e.target.value)} className="input w-full" />
                            </div>
                        </div>
                        <div className="w-full sm:w-1/2">
                            <span className="text-base sm:text-lg text-primary/75 leading-6">
                                Үйл ажиллагааны чиглэл
                            </span>
                            <div className="mt-2">
                                <input id="industry" name="industry" type="text" value={industry} onChange={(e) => setIndustry(e.target.value)} className="input w-full" />
                            </div>
                        </div>
                        <div className="w-full sm:w-1/2 flex justify-start">
                            <div className="w-1/3">
                                <span className="text-base sm:text-lg text-primary/75 leading-6">
                                    Ажилчдын тоо
                                </span>
                                <div className="mt-2">
                                    <input id="employeeCount" name="employeeCount" type="number" value={employeeCount} onChange={(e) => setEmployeeCount(e.target.value)} className="input w-full"/>
                                </div>
                            </div>
                        </div>
                        <div className="w-full sm:w-1/2">
                            <span className="text-base sm:text-lg text-primary/75 leading-6">
                                Таны албан тушаал
                            </span>
                            <div className="mt-2">
                                <input id="position" name="position" type="text" value={position} onChange={(e) => setPosition(e.target.value)} className="input w-full" />
                            </div>
                        </div>
                        <div className="w-full sm:w-1/2">
                            <span className="text-base sm:text-lg text-primary/75 leading-6">
                                Таны имэйл хаяг
                            </span>
                            <div className="mt-2">
                                <input id="email" name="email" type="email" value={email} onChange={(e) => setEmail(e.target.value)} onKeyDown={handleKeyDown} autoComplete='email' className="input w-full" />
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col gap-y-2.5 items-center">
                        { loading ? (
                            <button type="button" className="w-full sm:w-1/2 flex justify-center rounded-[5px] bg-modal px-[68px] py-[7px] text-base sm:text-lg font-semibold text-white leading-6 sm:leading-7 shadow-sm cursor-not-allowed" >
                                Уншиж байна..
                            </button>
                        ) : (
                            <button type="button" onClick={handleContactRequest} disabled={isButtonDisabled} className="w-full sm:w-1/2 flex justify-center items-center rounded-[5px] bg-modal py-2.5 h-[42px] text-base sm:text-lg font-semibold text-white leading-[22px] tracking-[1%] shadow-sm hover:bg-modal/90 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-modal/90 transition-colors duration-300">
                                Илгээх
                            </button>
                        )}
                        <span className="w-full sm:w-1/2 text-sm text-primary/75 font-normal leading-5 tracking-[1%]">
                            Бид таны и-мэйлийг бусадтай хуваалцахгүй, сурталчилгаанд ашиглахгүй
                        </span>
                    </div>
                </motion.div>
            </div>
        </div>
    )
}