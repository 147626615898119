import Hero from "../components/home/Hero"
import Features from "../components/home/Features"
import Testimonials from "../components/home/Testimonials"
import Mobile from "../components/home/Mobile"
import Security from "../components/home/Security"
import Modules from "../components/home/Modules"
import Extra from "../components/home/Extra"
import Companies from "../components/home/Companies"
import CTA from "../components/home/CTA"
import Price from "../components/home/Price"
import Contact from "../components/home/Contact"
import Slogan from "../components/home/Slogan"
import Footer from "../components/home/Footer"

export default function Home() {
    return (
        <div>
            <Hero />
            <Companies />
            <Features />
            <Mobile />
            <Security />
            <Modules />
            <Extra />
            <Testimonials />
            <CTA />
            <Price />
            <Contact />
            <Slogan />
            <Footer />
        </div>
    )
}