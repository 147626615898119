import { motion } from 'framer-motion'

export default function Features() {
    return (
        <>
            <div className="overflow-hidden bg-white pt-[100px] sm:pt-10 max-w-[1440px] mx-auto">
                <div className="mx-auto grid grid-cols-1 gap-y-[30px] lg:mx-0 lg:max-w-none lg:grid-cols-2">
                    <div className="lg:mx-auto flex items-center">
                        <div className="px-5 lg:px-0 lg:max-w-md">
                            <motion.p initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.2 }} viewport={{ once: true }} className="text-[26px] font-semibold text-primary sm:text-[32px] leading-7 sm:leading-10 tracking-[2%]">Цаг бүртгэл</motion.p>
                            <motion.div initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.6 }} viewport={{ once: true }} className="mt-[30px] space-y-5 sm:space-y-[30px] text-lg leading-7 text-primary/75 tracking-[1%]">
                                <p>Байршлаар болон байгууллагын Wi-Fi холболтоор цаг бүртгэх сонголттой</p>
                                <p>Стандарт болон Уян хатан цагаар бүртгэнэ</p>
                                <p className='sm:max-w-[354px]'>Цаг бүртгэх байршил тус бүрт ялгаатай нэмэгдэл/олговор тооцох боломжтой</p>
                            </motion.div>
                            <motion.div initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.7 }} viewport={{ once: true }} className='flex items-center mt-[30px] sm:mt-20'>
                                <a href="/attendances" className="text-center text-base sm:text-lg whitespace-nowrap font-semibold leading-[22px] text-primary px-[15px] py-2 sm:px-8 sm:py-1.5 h-[42px] sm:w-[262px] inline-block border-[1px] border-primary rounded-[5px] tracking-[2%] hover:bg-primary hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary transition-colors duration-300">
                                    Дэлгэрэнгүй танилцах
                                </a>
                            </motion.div>
                        </div>
                    </div>
                    <motion.div initial={{ opacity: 0, x: -40 }} whileInView={{ opacity: 1, x: 0 }} transition={{ delay: 0.6, duration: 0.6 }} viewport={{ once: true }} className="flex items-start justify-end lg:order-first relative">
                        {/* <img src='/main1.png' alt="Product screenshot" className="w-[48rem] sm:w-[57rem] max-w-none" /> */}
                        <img src='/main1-mobile.webp' alt="feature1 mobile" className="block sm:hidden w-full max-w-full" />
                        <img src='/main1.webp' alt="feature1" className="hidden sm:block w-full max-w-full" />
                    </motion.div>
                </div>
            </div>

            <div className="overflow-hidden bg-white py-[150px] sm:py-[340px]">
                <div className='max-w-[1440px] mx-auto relative isolate'>
                    <div className="mx-auto grid grid-cols-1 gap-y-[40px] sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                        <motion.div initial={{ opacity: 0, x: 40 }} whileInView={{ opacity: 1, x: 0 }} transition={{ delay: 0.6, duration: 0.6 }} viewport={{ once: true }} className="pl-4 sm:pl-0 items-end justify-start relative">
                            <img src='/sticker2.webp' alt="sticker2" className='absolute w-[84px] h-[84px] -top-[74px] right-2 sm:-top-[154px] sm:left-16 z-50'/>
                            <img src='/main2.webp' alt="feature2" className="-top-20 w-[800px] sm:w-[1181px] h-auto max-w-none lg:absolute" />
                        </motion.div>
                        <div className="lg:mx-auto flex items-center order-first">
                            <div className="px-5 lg:px-0 lg:max-w-[400px]">
                                <motion.p initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.2 }} viewport={{ once: true }} className="text-[26px] font-semibold text-primary sm:text-[32px] leading-7 sm:leading-10 tracking-[2%]">Цалин тооцоолол</motion.p>
                                <motion.div initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.6 }} viewport={{ once: true }} className="mt-[30px] space-y-5 sm:space-y-[30px] text-lg leading-7 text-primary/75 tracking-[1%]">
                                    <p>Цаг бүртгэл, Цалин тохиргооны дагуу ажилчдын цалинг автоматаар тооцоолно</p>
                                    <p>НДШ, ХХОАТ-н тайлан тайлагнахад бэлэн excel файл үүсгэнэ</p>
                                    <p>Ээлжийн амралтын олговор тооцоолно</p>
                                </motion.div>
                                <motion.div initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.7 }} viewport={{ once: true }} className='flex items-center mt-[30px] sm:mt-20'>
                                    <a href="/salary" className="text-center text-base sm:text-lg whitespace-nowrap font-semibold leading-[22px] text-primary px-[15px] py-2 sm:px-8 sm:py-1.5 h-[42px] sm:w-[262px] inline-block border-[1px] border-primary rounded-[5px] tracking-[2%] hover:bg-primary hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary transition-colors duration-300">
                                        Дэлгэрэнгүй танилцах
                                    </a>
                                </motion.div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
