const navigation = {
    navs: [
        { name: 'Бидний тухай', href: "https://www.gitm.mn/" },
        { name: 'Үйлчилгээний нөхцөл', href: '/term' },
        { name: 'Нууцлал', href: '/privacy' },
    ],
    social: [
        {
            name: 'Facebook',
            href: '#',
            icon: (props) => (
                <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
                    <path fillRule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clipRule="evenodd" />
                </svg>
            ),
        },
        {
            name: 'YouTube',
            href: '#',
            icon: (props) => (
                <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
                    <path fillRule="evenodd" d="M19.812 5.418c.861.23 1.538.907 1.768 1.768C21.998 8.746 22 12 22 12s0 3.255-.418 4.814a2.504 2.504 0 0 1-1.768 1.768c-1.56.419-7.814.419-7.814.419s-6.255 0-7.814-.419a2.505 2.505 0 0 1-1.768-1.768C2 15.255 2 12 2 12s0-3.255.417-4.814a2.507 2.507 0 0 1 1.768-1.768C5.744 5 11.998 5 11.998 5s6.255 0 7.814.418ZM15.194 12 10 15V9l5.194 3Z" clipRule="evenodd" />
                </svg>
            ),
        },
    ],
}
  
export default function Footer() {
    return (
        <footer className="bg-white px-5 lg:px-[50px] max-w-[1440px] mx-auto" aria-labelledby="footer-heading">
            <h2 id="footer-heading" className="sr-only">Footer</h2>
            <div className="sm:pt-[90px]">
                <div className="xl:grid xl:grid-cols-3">
                    <img src='/logo.webp' alt='Logo' className="w-[100px] sm:w-[120px]"/>
                    <div className="mt-10 sm:mt-16 grid gap-8 xl:col-span-2 xl:mt-0">
                        <div className="md:grid md:grid-cols-3">
                            <div className="sm:max-w-[184px]">
                                <h3 className="text-base sm:text-lg font-semibold leading-6 text-primary">Холбоос</h3>
                                <ul className="mt-5 space-y-[15px]">
                                    {navigation.navs.map((item) => (
                                    <li key={item.name}>
                                        <a href={item.href} className="text-base sm:text-lg leading-6 text-primary hover:text-primary/80">
                                        {item.name}
                                        </a>
                                    </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="mt-10 md:mt-0 sm:max-w-[168px]">
                                <h3 className="text-base sm:text-lg font-semibold leading-6 text-primary">Холбоо барих</h3>
                                <ul className="mt-5 space-y-[15px]">
                                    <li> <span className="text-base sm:text-lg leading-6 text-primary"> 9819 3877 </span> </li>
                                    <li> <a href="mailto:support@amarhr.mn" className="text-base sm:text-lg leading-6 text-[#2137FF] hover:text-[#2137FF]/80"> support@amarhr.mn </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="mt-10 md:mt-0 sm:max-w-[367px]">
                                <h3 className="text-base sm:text-lg font-semibold leading-6 text-primary">Байршил</h3>
                                <ul className="mt-5 space-y-[15px]">
                                    <li>
                                        <span className="text-base sm:text-lg leading-6 text-primary sm:max-w-[367px]">
                                            УБ, Хан-уул дүүрэг, 15-р хороо,
                                            Махатма Гандигийн гудамж, Галакси Тауэр
                                            4005 тоот 
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-8 border-t border-primary pt-4 sm:pt-8 md:flex md:items-center md:justify-between">
                    <div className="flex space-x-6 md:order-2"></div>
                    <p className="flex flex-col sm:flex-row items-center justify-center text-center my-[15px] sm:my-[30px] gap-[5px] text-base sm:text-lg leading-6 text-primary md:order-1 md:mt-0">
                        {new Date().getFullYear()} GITM LLC<span className="sm:ml-[25px]"><img src='/copyright.svg'/></span>Зохиогчийн эрх хуулиар хамгаалагдсан
                    </p>
                </div>
            </div>
        </footer>
    )
}
  