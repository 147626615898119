export default function SuccessModal({ setOpen }) {
    return (
        <div>
            <div className='px-10 mt-16 flex flex-col items-center justify-center'>
                <div className='text-2xl sm:text-[32px] text-primary leading-7 sm:leading-10 tracking-[2%]'>Баярлалаа</div>
                <p className='mt-[30px] text-center text-base sm:text-lg leading-6 sm:leading-7 text-primary/75 tracking-[1%] max-w-[315px]'>Бид таны и-мэйл хаяг руу бүртгэлтэй холбоотой мэдээлэл илгээх болно</p>
                <button type="button" onClick={() => setOpen(false)} className="mt-24 w-full flex justify-center items-center rounded-[5px] bg-modal py-2.5 h-[42px] text-base sm:text-lg font-semibold text-white leading-[22px] tracking-[1%] shadow-sm hover:bg-modal/90 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-modal/90 transition-colors duration-300">
                    Хаах
                </button>
            </div>
        </div>
    )
}