import { motion } from 'framer-motion'

const features = [
    { id: 1, title: "Google Cloud Platform", desc: "Хэрэглэгчдийн мэдээлэл үүлэн технологийн серверт нууцлалтай хадгалагдана", icon: '/cloud-lock.svg' },
    { id: 2, title: "Secure sockets layer", desc: "Сервер болон хэрэглэгчийн хооронд дамжих мэдээлэл тусгай кодчиллоор хамгаалагдсан", icon: '/shield-check.svg' },
    { id: 3, title: "Тасалдалгүй ажиллагаа", desc: "Сервер инженерүүд серверийн үйл ажиллагааг 24/7 хянаж ажиллана", icon: '/infinity.svg' },
]

export default function Security() {
    return (
        <div className="bg-white px-5 lg:px-[50px] max-w-[1440px] mx-auto">
            <div className="py-[150px] sm:py-60">
                <motion.div initial={{ opacity: 0, y: -10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.2 }} viewport={{ once: true }} className="text-[26px] font-semibold text-primary sm:text-[32px] leading-7 sm:leading-10 tracking-[2%]">
                    Хамгаалалт, аюулгүй ажиллагаа
                </motion.div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mt-[26px] sm:mt-[50px] gap-[15px] sm:gap-10">
                    { features.map((feature, index) => (
                        <motion.div initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.3 * (index + 1), duration: 0.4 }} viewport={{ once: true }} key={index} className="bg-primary text-white border border-zinc-700 rounded-[7px] px-[25px] pt-5 pb-[30px] sm:pb-[46px] tracking-[1%]">
                            <div className="flex flex-col gap-[5px] sm:gap-2 justify-center">
                                <img src={feature.icon} alt={feature.title} className="h-10 w-10" />
                                <span className="text-base sm:text-lg font-semibold leading-6 sm:leading-7 tracking-[1%]">{feature.title}</span>
                            </div>
                            <div className="mt-5 text-base sm:text-lg leading-6 sm:leading-7 tracking-[1%]">
                                { feature.desc }
                            </div>
                        </motion.div>
                    )) }
                </div>
                <motion.div initial={{ opacity: 0, y: -10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.2 }} viewport={{ once: true }} className="text-base sm:text-lg text-[#272E3B] mt-[30px] sm:mt-10 leading-6 sm:leading-7 tracking-[1%] max-w-[1188px]">
                    Бид хэрэглэгчдийнхээ Мэдээллийн нууцлал, Системийн тогтвортой ажиллагааг мэдээлэл технологийн салбарт 30 жилийн туршлагатай <a href="https://datacom.mn/" target='_blank'><span className='text-accent font-semibold cursor-pointer'>Датаком компани</span></a>тай хамтран гүйцэтгэдэг
                </motion.div>
            </div>
        </div>
    )
}