import Dz from '../../assets/dz.webp'
import Lps from '../../assets/lps.webp'
import Businessmn from '../../assets/businessmn.webp'
import Artisan from '../../assets/artisan.webp'

import { motion } from 'framer-motion'

const companies = [
    {name: 'DZ', logo: Dz},
    {name: 'BusinessMN', logo: Businessmn},
    {name: 'Artisan', logo: Artisan},
    {name: 'LPS', logo: Lps},
]

export default function Companies(){
    return (
        <div className='px-5 lg:px-[50px] max-w-[1440px] mx-auto bg-white sm:py-[140px]'>
            <motion.div initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.2 }} viewport={{ once: true }}>
                <h1 className="text-base sm:text-lg font-semibold text-center leading-[22px] tracking-[2%]">Манай эрхэм хэрэглэгчид</h1>
                <div className='logos mt-[30px] sm:mt-[70px]'>
                    <div className='logos-mobile-slide flex sm:hidden'>
                        { companies.map((company, index) => (
                            <img key={index} src={company.logo} alt={company.name} className="w-auto h-full object-cover" />
                        )) }
                    </div>
                    <div className='logos-slide hidden sm:flex'>
                        { companies.map((company, index) => (
                            <img key={index} src={company.logo} alt={company.name} className="w-auto h-full object-cover" />
                        )) }
                    </div>
                </div>
            </motion.div>
        </div>
    )
}