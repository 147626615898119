import { motion } from 'framer-motion'
import { ChevronRight } from 'lucide-react'

import Logo from '../../assets/logo-white.webp';
import CTAImage from '../../assets/cta.webp'

export default function CTA() {
    return (
        <div className='bg-white px-5 lg:px-[50px] max-w-[1440px] mx-auto my-[150px] sm:mt-[180px] sm:mb-[220px]'>
            <motion.div initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.2 }} viewport={{ once: true }} style={{ backgroundImage: `url(${CTAImage})` }} className="bg-cover bg-center bg-no-repeat rounded-[20px]">
                <div className="flex flex-col pt-10 px-[50px]">
                    <motion.div initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.6 }} viewport={{ once: true }}>
                        <img src={Logo} alt="Logo" className="w-[120px]" />
                    </motion.div>
                    <motion.p initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.7 }} viewport={{ once: true }} className="mt-5 text-lg sm:text-[22px] max-w-[690px] font-normal text-white leading-8 tracking-[2%]">Бид хэрэглэгчдийнхээ санал шүүмжийг үндэслэн системээ тогтмол сайжруулж байхаас гадна хагас жил тутамд Цаг бүртгэл, Цалин тооцооллын дэвшилтэт модулиудыг нэвтрүүлнэ</motion.p>
                    <motion.div initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.8 }} viewport={{ once: true }} className="text-base font-semibold sm:text-lg leading-6 text-white flex items-center mt-6 mb-[30px]">
                        <a href="https://www.gitm.mn/" className='flex gap-[5px] justify-center items-center'>Бидний тухай <span aria-hidden="true"><ChevronRight className='w-5 h-5 sm:w-6 sm:h-6'/></span></a>
                    </motion.div>
                </div>
            </motion.div>
        </div>
    )
}