import { Carousel } from "flowbite-react"
import { motion } from 'framer-motion'

export default function Testimonials() {
    return (
        <div className="bg-white px-5 lg:px-[50px] max-w-[1440px] mx-auto sm:mt-[60px]">
            <motion.section initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.4 }} viewport={{ once: true }} className="bg-white h-[340px]">
                <Carousel theme={CarouselOption} draggable={false}>
                    <div className="mx-auto flex flex-row gap-x-[53px] items-center">
                        <img className="hidden sm:block h-[200px] w-[165px]" src={'/test1.webp'} alt="" />
                        <div className="flex flex-col justify-center sm:max-w-[670px]">
                            <blockquote>
                                <div className="relative text-lg font-normal sm:text-[26px] leading-8 sm:leading-[34px] tracking-[0%] text-primary/75">
                                    <span className="text-accent absolute -top-6 -left-0 sm:-left-6 sm:-top-0 text-xl">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="14" fill="none" viewBox="0 0 16 14" > <path fill="#0057D8" d="M8.52 13.94l2.58-6.9H9.36L10.8.2h5.04L14.4 7.04l-3.24 6.9H8.52zm-7.86 0l2.58-6.9H1.5L2.94.2h5.04L6.54 7.04l-3.24 6.9H.66z" ></path> </svg>
                                    </span>
                                    Сунаж суудаг цаг байхгүй болсон. Тайлан бэлдэх, цалин бодох үед ажилчдынхаа ирсэн тарсан цагтай тулгах гэх мэт …. одоо амар болсон.
                                </div>
                            </blockquote>
                            <figcaption className="flex items-center gap-x-[15px] sm:gap-x-[30px] mt-10">
                                <img className="block sm:hidden h-[80px] w-[70px]" src={'/test1.webp'} alt="" />
                                <div className="text-sm leading-6">
                                    <div className="font-semibold text-primary text-base leading-[22px] tracking-[1%] italic">Б.Нарангэрэл</div>
                                    <div className="mt-1.5 text-primary/75 text-base leading-[22px] tracking-[1%]">Бизнес Медиа ХХК / Нягтлан бодогч</div>
                                </div>
                            </figcaption>
                        </div>
                    </div>

                    <div className="mx-auto flex flex-row gap-x-[53px] items-center">
                        <img className="hidden sm:block h-[200px] w-[165px]" src={'/test2.webp'} alt="" />
                        <div className="flex flex-col justify-center sm:max-w-[729px]">
                            <blockquote>
                                <div className="relative text-lg font-normal sm:text-[26px] leading-8 sm:leading-[34px] tracking-[0%] text-primary/75">
                                    <span className="text-accent absolute -top-6 -left-0 sm:-left-6 sm:-top-0 text-xl">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="14" fill="none" viewBox="0 0 16 14" > <path fill="#0057D8" d="M8.52 13.94l2.58-6.9H9.36L10.8.2h5.04L14.4 7.04l-3.24 6.9H8.52zm-7.86 0l2.58-6.9H1.5L2.94.2h5.04L6.54 7.04l-3.24 6.9H.66z" ></path> </svg>
                                    </span>
                                    Тулгах, шалгах гэх мэт төвөгтэй ажил бодохоо больсон. Менежер нягтлан хоёр маань амар эйч ар дээр ажиллаад би хаяа орж мэдээллүүдтэй танилцаад л болоо.
                                </div>
                            </blockquote>
                            <figcaption className="flex items-center gap-x-[15px] sm:gap-x-[30px] mt-10">
                                <img className="block sm:hidden h-[80px] w-[70px]" src={'/test2.webp'} alt="" />
                                <div className="text-sm leading-6">
                                    <div className="font-semibold text-primary text-base leading-[22px] tracking-[1%] italic">Г.Мөнгөндолгио</div>
                                    <div className="mt-1.5 text-primary/75 text-base leading-[22px] tracking-[1%]">Artisan Sourdough Bakery / Үүсгэн байгуулагч</div>
                                </div>
                            </figcaption>
                        </div>
                    </div>

                    <div className="mx-auto flex flex-row gap-x-[53px] items-center">
                        <img className="hidden sm:block h-[200px] w-[165px]" src={'/test3.webp'} alt="" />
                        <div className="flex flex-col justify-center sm:max-w-[729px]">
                            <blockquote>
                                <div className="relative text-lg font-normal sm:text-[26px] leading-8 sm:leading-[34px] tracking-[0%] text-primary/75">
                                    <span className="text-accent absolute -top-6 -left-0 sm:-left-6 sm:-top-0 text-xl">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="14" fill="none" viewBox="0 0 16 14" > <path fill="#0057D8" d="M8.52 13.94l2.58-6.9H9.36L10.8.2h5.04L14.4 7.04l-3.24 6.9H8.52zm-7.86 0l2.58-6.9H1.5L2.94.2h5.04L6.54 7.04l-3.24 6.9H.66z" ></path> </svg>
                                    </span>
                                    Өргөдөл, хүсэлт шийдэх, цалин бас ээлжийн амралт автоматаар бодох функц нь супер, Ажил амар болсон.
                                </div>
                            </blockquote>
                            <figcaption className="flex items-center gap-x-[15px] sm:gap-x-[30px] mt-10">
                                <img className="block sm:hidden h-[80px] w-[70px]" src={'/test3.webp'} alt="" />
                                <div className="text-sm leading-6">
                                    <div className="font-semibold text-primary text-base leading-[22px] tracking-[1%] italic">Ж.Энхжаргал</div>
                                    <div className="mt-1.5 text-primary/75 text-base leading-[22px] tracking-[1%]">DZ consulting / Нягтлан бодогч</div>
                                </div>
                            </figcaption>
                        </div>
                    </div>
                </Carousel>
            </motion.section>
        </div>
    )
}

const CarouselOption = {
    "root": {
        "base": "relative h-full w-full",
        "leftControl": "absolute left-0 top-0 flex h-full items-center justify-center px-4 focus:outline-none",
        "rightControl": "absolute right-0 top-0 flex h-full items-center justify-center px-4 focus:outline-none"
    },
    "indicators": {
        "active": {
            "off": "bg-indicator/20",
            "on": "bg-accent"
        },
        "base": "h-3 w-3 rounded-full",
        "wrapper": "absolute bottom-0 left-1/2 flex -translate-x-1/2 space-x-3"
    },
    "item": {
        "base": "flex flex-row items-center justify-center",
        "wrapper": {
            "off": "w-full flex-shrink-0 transform cursor-default snap-center items-center justify-center flex",
            "on": "w-full flex-shrink-0 transform cursor-grab snap-center pointer-events-none items-center justify-center flex"
        }
    },
    "control": {
        "base": "lg:mx-8 hidden lg:inline-flex h-8 w-8 items-center justify-center rounded-full bg-indicator/20 group-focus:outline-none group-focus:ring-4 group-focus:ring-white sm:h-10 sm:w-10",
        "icon": "h-5 w-5 text-modal sm:h-6 sm:w-6"
    },
    "scrollContainer": {
        "base": "flex h-full snap-mandatory overflow-y-hidden overflow-x-scroll scroll-smooth rounded-lg",
        "snap": "snap-x"
    }
}