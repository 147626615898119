import { useState } from 'react'
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react'
import { ExclamationTriangleIcon, XMarkIcon } from '@heroicons/react/24/outline'

export default function Modal({ children, open, setOpen }) {
    return (
        <Dialog open={open} onClose={setOpen} className="relative z-50">
            <DialogBackdrop transition className="fixed inset-0 bg-gray-500 bg-opacity-50 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in" />

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-screen items-center justify-center p-4 text-center sm:p-0">
                    <DialogPanel transition className="relative transform overflow-hidden rounded-lg bg-white pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-4xl sm:pt-[30px] sm:pb-[60px] sm:px-0 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95" >
                        <div className="absolute right-0 top-0 pr-[25px] pt-[25px]">
                            <button type="button" onClick={() => setOpen(false)} className="rounded-md bg-white text-closemodal hover:text-closemodal/90 focus:outline-none focus:ring-2 focus:ring-closemodal/90 focus:ring-offset-2" >
                                <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                            </button>
                        </div>
                        <div className="sm:flex sm:items-start">
                            { children }
                        </div>
                    </DialogPanel>
                </div>
            </div>
        </Dialog>
    )
}
