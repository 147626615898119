import { useState, useEffect } from 'react'
import { motion } from 'framer-motion'
import { Dialog, DialogBackdrop, DialogPanel } from '@headlessui/react'

const modules = [
    { id: 1, name: "Системийн тохиргоо", image: '/module1.webp', desc: ["Системийн тохиргоонд бүх төрлийн Цаг бүртгэл, Цалин тооцооллыг тохируулах боломжтой"]},
    { id: 2, name: "Админ тохиргоо", image: '/module2.webp', desc: ["Хэдэн ч админ үүсгэж болох ба админ тус бүрийн систем дээр хийх үйлдлийг сонгон тохируулна"]},
    { id: 3, name: "Хяналтын самбар", image: '/module3.webp', desc: ["Мэдээллийн хураангуйг хялбар ойлгомжтой харуулсан"]},
    { id: 4, name: "Өргөдөл/Хүсэлт", image: '/module4.webp', desc: ["Ажилтнаас ирүүлсэн өргөдөл хүсэлтийг хялбар шийдвэрлэнэ", "Таны шийдвэр ажилтны апп-руу мэдэгдлээр очно", "Ирүүлсэн, шийдвэрлэсэн бүх өргөдөл хүсэлтүүд хадгалагдана"]},
    { id: 5, name: "Зарлал/Мэдээлэл", image: '/module5.webp', desc: ["Зарлал мэдээллийг алба хэлтсээр нь эсвэл ажилтан тус бүрээр нь сонгон илгээх боломжтой"]},
    { id: 6, name: "Ажилтны хүснэгт", image: '/module6.webp', list:["ажилтан тус бүрийн дэлгэрэнгүй мэдээлэлтэй танилцах, засах", "ажилтны бүхий л түүхүүдийг хадгалах байгууллагын Хүний нөөцийн Клауд сан болно"], desc: ["Ажилтны хүснэгт нь зөвхөн бүртгэлтэй ажилчдын жагсаалтаас гадна", "Шаардлагатай мэдээллийг хүснэгтэд харуулах, дарааллыг өөрчлөн өөрт эвтэйхнээр ашиглах боломжтой"]},
]

export default function Modules() {
    const [open, setOpen] = useState(false)
    const [selected, setSelected] = useState(modules[0])
    const [selectedModule, setSelectedModule] = useState(null)

    useEffect(() => {
        if(selected.modules) {
            setSelectedModule(selected.modules[0])
        }
    }, [selected])

    useEffect(() => {
        if(!selectedModule || open) return
        const interval = setInterval(() => {
            if(open === false){
                console.log(selectedModule)
                const currentIndex = selected.modules.findIndex(module => module.id === selectedModule.id)
                const nextIndex = selected.modules.length - 1 === currentIndex ? 0 : currentIndex + 1
                setSelectedModule(selected.modules[nextIndex])
            }
        }, 5000)

        return () => clearInterval(interval)
        // eslint-disable-next-line
    }, [selectedModule, open])

    const changeTab = (module) => {
        setSelected(module)
    }

    return (
        <div>
            <div className="bg-white mt-5">
                <div className="px-5 lg:px-[50px] max-w-[1440px] mx-auto">
                    <motion.h1 initial={{ opacity: 0, y: -10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.2 }} viewport={{ once: true }} className="text-[26px] font-semibold text-primary sm:text-[32px] leading-7 sm:leading-10 tracking-[2%]">Бусад модулиуд</motion.h1>
                    <div className="overflow-x-auto flex flex-nowrap text-nowrap gap-[15px] mt-[22px] sm:mt-8 py-2">
                        { modules.map((module, index) => (
                            <motion.div initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.2 * index }} viewport={{ once: true }} className={`${selected.id === module.id ? 'text-primary font-semibold underline underline-offset-[6px] decoration-[2px] decoration-accent' : 'text-primary/50'} text-base font-semibold sm:text-lg tracking-[1%] leading-[22px] cursor-pointer`} onClick={() => changeTab(module)} key={module.id}>
                                { module.name }  
                            </motion.div>
                        )) }
                    </div>
                </div>

                <motion.div initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.2 }} viewport={{ once: true }} className="bg-background overflow-hidden mt-[30px] gap-x-4 gap-y-8">
                    {modules.map((module, index) => (
                        <div key={index} className={module.id === selected.id ? "flex flex-col sm:flex-row py-5 sm:py-[30px] px-5 lg:px-[50px] max-w-[1440px] mx-auto relative sm:h-[510px] justify-center" : "hidden"}>
                            <div className='sm:max-w-[919px] flex items-center justify-center'>
                                <div className='flex justify-center'>
                                    <img onClick={() => setOpen(true)} src={module.image} alt={module.title} className={module.id === 2 ? "w-[532px] h-auto cursor-pointer" : module.id === 4 ? "w-[673px] h-auto cursor-pointer" : module.id === 5 ? "w-[716px] h-auto cursor-pointer" : "max-w-none w-full h-auto cursor-pointer"} />
                                </div>
                            </div>
                            <div className={`lg:w-1/2 flex flex-col ${module.id === 2 ? 'sm:pl-[100px] sm:max-w-[542px]' : module.id === 4 ? 'sm:pl-[100px] sm:max-w-[520px]' : module.id === 5 ? 'sm:pl-[100px] sm:max-w-[591px]' : module.id === 6 ? 'xl:pt-[20px]' : 'sm:pl-[50px]'} ${module.id === 6 ? 'sm:pl-[50px] xl:pt-[30px]' : 'xl:pt-[90px]'} pb-5 space-y-[20px] order-first sm:order-last`}>
                                {Array.isArray(module.desc) ? (
                                    <>
                                        <p className='text-primary/75 sm:text-primary text-start text-base sm:text-lg leading-6 tracking-[1%] font-normal'>{module.desc[0]}</p>
                                        {module.id === 6 && module.list && (
                                            <ul className='text-primary/75 sm:text-primary text-start text-base sm:text-lg leading-6 tracking-[1%] font-normal space-y-5'>
                                                {module.list.map((listItem, i) => (
                                                    <li className='bulleted' key={i}>{listItem}</li>
                                                ))}
                                            </ul>
                                        )}
                                        {module.desc.slice(1).map((descItem, i) => (
                                            <p className='text-primary/75 sm:text-primary text-start text-base sm:text-lg leading-6 tracking-[1%] font-normal' key={i}>{descItem}</p>
                                        ))}
                                    </>
                                ) : (
                                    <p className='text-primary/75 sm:text-primary text-start text-base sm:text-lg leading-6 tracking-[1%] font-normal'>{module.desc}</p>
                                )}
                            </div>
                        </div>
                    ))}
                </motion.div>
            </div>

            <Dialog open={open} onClose={setOpen} className="relative z-50">
                <DialogBackdrop transition className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in" />

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex flex-col min-h-full justify-center text-center items-center sm:p-0">
                        <DialogPanel transition className="relative w-full transform overflow-hidden text-left transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:w-full sm:max-w-6xl data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95" >
                            { selectedModule ? <img src={selectedModule.image} alt={selectedModule.title} className="w-full" /> : <img src={selected.image} alt={selected.name} className="w-full" /> }
                        </DialogPanel>

                        <div onClick={() => setOpen(false)} className='text-white font-semibold cursor-pointer rounded-xl border-2 border-white py-2 px-4 mt-4'>
                            Цонх хаах
                        </div>
                    </div>
                </div>
            </Dialog>
        </div>
    )
}