import { useState } from 'react'
import { toast } from 'sonner'
import { sendAPIRequest } from '../../utils/request'
import { isValidEmail } from '../../utils/util'

export default function APIModal({ setOpen }) {
    const [email, setEmail] = useState('')
    const [loading, setLoading] = useState(false)
    const [isButtonDisabled, setIsButtonDisabled] = useState(false)
    const [buttonText, setButtonText] = useState('Илгээх')

    const handleKeyDown = (e) => {
        if(e.key === 'Enter') handleAPIRequest()
    }

    const handleAPIRequest = async () => {
        if(!email) return toast.error('Имэйл хаяг хоосон байна')
        if(!isValidEmail(email)) return toast.error('Имэйл хаяг буруу байна')
        setLoading(true)
        try {
            const res = await sendAPIRequest(email)
            if(!res) throw new Error('Имэйл хаяг илгээхэд алдаа гарлаа')
            toast.success('Имэйл хаяг амжилттай илгээгдлээ')
            setIsButtonDisabled(true)
            setButtonText('Илгээгдсэн')
        } catch (error) {
            toast.error('Имэйл хаяг илгээхэд алдаа гарлаа')
        } finally {
            setLoading(false)
        }
    }
    return (
        <div>
            <div className="flex gap-2 items-center px-10">
                <img src='/cloud-wired.svg' className="w-10 h-10" />
                <span className='text-lg sm:text-xl text-modal font-semibold leading-6 sm:leading-7'>API холболт</span>
            </div>
            <div className='px-10 mt-8'>
                <div className='text-2xl sm:text-[32px] text-primary leading-7 sm:leading-10 tracking-[2%]'>Дотоод программ хангамжтайгаа холбох боломжтой<span className='text-accent font-extrabold text-2xl sm:text-[32px] leading-7 sm:leading-10 tracking-[2%]'>.</span></div>
                <p className='mt-[30px] text-base sm:text-lg leading-6 sm:leading-7 text-primary/75 tracking-[1%]'>API Documentation болон заавар тун удахгүй...</p>
                <div className="bg-accent w-[188px] max-w-full h-0.5 my-[30px]"></div>
                <p className='mt-[30px] text-base sm:text-lg leading-6 sm:leading-7 text-primary/75 tracking-[1%]'>API Documentation бэлэн болох үед мэдэгдэл авахыг хүсвэл и-мэйлээ үлдээгээрэй</p>

                <div className='flex flex-col sm:flex-row gap-[15px] mt-[30px] mb-15'>
                    <div className='w-[350px] max-w-full'>
                        <span className="text-base sm:text-lg leading-6 text-primary/75">
                            Мэдэгдэл авах и-мэйл хаяг
                        </span>
                        <div className="mt-1.5">
                            <input id="email" name="email" type="email" value={email} onChange={(e) => setEmail(e.target.value)} autoComplete='email' onKeyDown={handleKeyDown} className="input"/>
                        </div>
                    </div>
                    <div className='flex justify-end items-end'>
                        { loading ? (
                            <button type="button" className="w-full flex justify-center rounded-[5px] bg-modal px-[68px] py-[7px] text-base sm:text-xl font-bold text-white leading-6 sm:leading-7 shadow-sm cursor-not-allowed" >
                                Уншиж байна..
                            </button>
                        ) : (
                            <button type="button" onClick={handleAPIRequest} disabled={isButtonDisabled} className="w-full sm:w-[200px] flex justify-center rounded-[5px] bg-modal px-[68px] py-[7px] text-base sm:text-xl font-bold text-white leading-6 sm:leading-7 shadow-sm hover:bg-modal/90 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-modal/90" >
                                {buttonText}
                            </button>
                        )}
                    </div>
                </div>

                <div className="sm:hidden mt-[30px] mb-[14px] w-full flex justify-center rounded-[5px] bg-gradient-to-r from-accent to-[#1a2f8b] px-[68px] py-[7px] text-base sm:text-xl font-bold text-white leading-6 sm:leading-7 shadow-sm hover:bg-accent/90 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent/90" onClick={() => setOpen(false)}>Нүүр хуудас</div>
            </div>
        </div>
    )
}