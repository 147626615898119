import React from 'react';
import { Toaster } from 'sonner';
import ReactDOM from 'react-dom/client';
import './index.css';
import Home from './pages/Home';
import Layout from './components/layout/Layout';
import Attendances from './pages/Attendances';
import Salary from './pages/Salary';
import Term from './pages/Term';
import Privacy from './pages/Privacy';
import NotFound from './pages/NotFound';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { CheckIcon, CircleAlert, CircleX, Info } from 'lucide-react';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <Toaster 
            position='top-center'
            duration={6000}
            toastOptions={{
                classNames: {
                    error: 'bg-red-500 text-white font-bold gap-4',
                    success: 'bg-green-400 text-white font-bold gap-4',
                    warning: 'bg-yellow-400 text-white font-bold gap-4',
                    info: 'bg-blue-400 text-white font-bold gap-4',
                }
            }}
            icons={{ 
                success: <CheckIcon />, 
                error: <CircleX />, 
                warning: <CircleAlert />, 
                info: <Info />
            }}
        />
        <Routes>
            <Route index element={<Layout><Home/></Layout>}/>
            <Route path="attendances" element={<Attendances />} />
            <Route path="salary" element={<Salary />} />
            <Route path="term" element={<Term />} />
            <Route path="privacy" element={<Privacy />} />
            <Route path="*" element={<NotFound />} />
        </Routes>
    </BrowserRouter>
);