import { useState, useEffect } from 'react'
import { motion } from 'framer-motion'

const prices = [
    { id: 'free', price: '50,000', desc: '9 хүртэлх ажилтан', per: 'Сарын' },
    { id: '4k', price: '4,000', desc: '9 өөс дээш ажилтан тус бүр', per: 'Сарын' },
]

function formatPrice(price){
    if(price === null) return '0₮'
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "₮"
}

export default function Price() {
    const [employeeCount, setEmployeeCount] = useState(9)
    const [monthly, setMonthly] = useState(null)
    const [annualy, setAnnualy] = useState(null)
    const [discount, setDiscount] = useState(null)

    useEffect(() => {   
        if(employeeCount === null) return
        calculate()
        // eslint-disable-next-line
    }, [])

    const calculate = () => {
        if(employeeCount < 10) {
            setMonthly(50000)
            setAnnualy(50000 * 12)
            setDiscount(Math.round(50000 * 12 * 0.85))
            return 
        } else {
            let month = Math.round(50000 + (employeeCount - 9) * 4000)
            setMonthly(month)
            setAnnualy(Math.round(month * 12))
            setDiscount(Math.round(month * 12 * 0.85))
        }
    }

    return (
    <motion.div initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.2 }} viewport={{ once: true }} className='bg-white px-5 lg:px-[50px] max-w-[1440px] mx-auto'>
            <div className="flex gap-4 items-center">
                <h1 className="sm:mt-2 text-[26px] font-semibold text-primary sm:text-[32px] leading-7 sm:leading-10 tracking-[2%]">Үнийн санал</h1>
            </div>
            <div className='flex flex-col items-center lg:items-start justify-center'>
                <div className="grid grid-cols-1 lg:grid-cols-4 mt-10 gap-10 lg:gap-0 max-w-7xl">
                    { prices.map(price => (
                        <div className="flex flex-col gap-[5px] sm:gap-6 justify-center" key={price.id}>
                            <p className="text-base sm:text-lg text-primary leading-6 sm:leading-7 tracking-[1%] text-start">{price.desc}</p>
                            <div className='flex gap-2.5 items-end'>
                                <h1 className="text-2xl sm:text-[34px] text-primary font-semibold leading-7 sm:leading-[38px] tracking-[2%]">
                                    {price.price}
                                    <span className='font-light'>₮</span>
                                </h1>
                                <p className="text-primary text-base sm:text-lg leading-6 sm:leading-7 tracking-[1%]">{price.per}</p>
                            </div>
                        </div>
                    )) }
                    <div className='flex flex-row flex-wrap ml-0 lg:ml-32 xl:ml-52'>
                        <div className="flex flex-col">
                            <h1 className="text-base sm:text-lg text-primary leading-6 sm:leading-[22px] tracking-[2%]">Үнэ тооцоолох</h1>
                            <div className='flex gap-[7px] items-center mt-[15px] sm:mt-5'>
                                <input type="number" value={employeeCount} onChange={(e) => setEmployeeCount(e.target.value)} placeholder="0" className="input text-center !w-[88px] !h-[42px]" />
                                <span className="label !text-base !sm:text-lg !text-primary/75 !leading-6 !sm:leading-7 text-nowrap">
                                    Ажилтны тоо оруулна уу
                                </span>
                            </div>
                            <button type="button" onClick={calculate} className="flex w-full mt-[15px] lg:mt-[25px] justify-center items-center rounded-[5px] bg-price py-2.5 px-6 font-semibold text-white text-lg leading-[22px] tracking-[2%] shadow-sm hover:bg-price/90 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:bg-price/90" >
                                Үнэ тооцоолох
                            </button>
                            <div className='w-full flex flex-col gap-2.5 mt-[30px]'>
                                <div className='flex justify-between'>
                                    <span className='text-[#505050] text-base sm:text-lg leading-6 sm:leading-7 tracking-[2%]'>Сарын</span>
                                    <span className='text-modal text-base sm:text-lg leading-6 sm:leading-7 tracking-[2%]'>{formatPrice(monthly)}</span>
                                </div>
                                <div className='flex justify-between'>
                                    <span className='text-[#505050] text-base sm:text-lg leading-6 sm:leading-7 tracking-[2%]'>Жилийн</span>
                                    <span className='text-modal text-base sm:text-lg leading-6 sm:leading-7 tracking-[2%]'>{formatPrice(annualy)}</span>
                                </div>
                                <div className='flex justify-between'>
                                    <div className='text-[#505050] text-base sm:text-lg leading-6 sm:leading-7 tracking-[2%]'>Жилээр төлбөл <span className='text-price'>-15%</span></div>
                                    <span className='text-price text-base sm:text-lg leading-6 sm:leading-7 tracking-[2%]'>{formatPrice(discount)}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='flex flex-col sm:flex-row justify-start items-center mt-[50px] gap-x-4 gap-y-4'>
                <img className='w-[84px] h-[84px]' src='/sticker5.webp' alt='sticker5' />
                <p className='text-center sm:text-start text-base sm:text-lg text-primary/70 italic leading-[22px] sm:max-w-[557px]'>Цаг бүртгэл, цалин тооцоолол, дээр нь өргөдөл хүсэлт, ажилчдын клауд сан гээд функцүүдэд арай хямдхан уу гайгүй юу</p>
            </div>
        </motion.div>
    )
}