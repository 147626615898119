import { useState, useEffect } from 'react'
import { motion, useMotionValue, useTransform, animate } from 'framer-motion'

const TOTAL_EMP = 156

const tabs = [
    { id: 1, icon: '/micon1.svg', dis: '/micon1-dis.svg', screen: '/mobile-main.webp', mobile: 'mobile-main-m.webp' },
    { id: 2, icon: '/micon2.svg', dis: '/micon2-dis.svg', screen: '/mobile2.webp', mobile: 'mobile2-m.webp' },
    { id: 3, icon: '/micon3.svg', dis: '/micon3-dis.svg', screen: '/mobile3.webp', mobile: 'mobile3-m.webp' },
    { id: 4, icon: '/micon4.svg', dis: '/micon4-dis.svg', screen: '/mobile4.webp', mobile: 'mobile4-m.webp' },
    { id: 5, icon: '/micon5.svg', dis: '/micon5-dis.svg', screen: '/mobile5.webp', mobile: 'mobile5-m.webp' },
    { id: 6, icon: '/micon6.svg', dis: '/micon6-dis.svg', screen: '/mobile6.webp', mobile: 'mobile6-m.webp' },
]

function formatDate(date) {
    const d = new Date(date)
    const m = d.getMonth() + 1
    const day = d.getDate()
    return `${d.getFullYear()}/${m.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}`
}

export default function Mobile(){
    const [open, setOpen] = useState(false)
    const [tab, setTab] = useState(tabs[0])

    const count = useMotionValue(0);
    const rounded = useTransform(count, Math.round);

    useEffect(() => {
        const animation = animate(count, TOTAL_EMP, { duration: 2 });

        return animation.stop;

        // eslint-disable-next-line
    }, [])

    return (
        <div className="overflow-hidden bg-white max-w-[1440px] mx-auto">
                <div className="mx-auto grid grid-cols-1 gap-y-[30px] sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                    <div className="px-5 lg:px-0 lg:mx-auto flex items-center">
                        <div>
                            <div className="lg:max-w-md">
                                <motion.p initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.2 }} viewport={{ once: true }} className="sm:mt-2 text-[26px] font-semibold text-primary sm:text-[32px] leading-7 sm:leading-10 tracking-[2%]">Ажилтны апп</motion.p>
                                <motion.div initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.6 }} viewport={{ once: true }} className="mt-7 sm:mt-10 space-y-[15px] text-lg leading-7 text-primary/75 tracking-[1%]">
                                    <p>Цагаа бүртгүүлэх</p>
                                    <p>Өргөдөл, хүсэлт илгээх</p>
                                    <p>Зарлал, мэдээлэл авах</p>
                                    <p>Цалингийн задаргаатай танилцах</p>
                                    <p>Гэрээ & Ажлын байрны мэдээлэлтэй танилцах боломжтой</p>
                                </motion.div>
                            </div>
                            <div className='mt-[60px] mb-[30px] sm:mb-0 sm:mt-[100px] lg:max-w-[493px]'>
                                <span className='text-sm text-[#FF00D6] text-center sm:text-left'>{formatDate(new Date())}</span>
                                <div className='flex flex-col sm:flex-row justify-center items-center gap-2.5 sm:gap-5 mt-[5px] sm:mt-0 relative'>
                                    <img src='/sticker3.webp' alt='sticker3' className='w-[84px] h-[84px] absolute -top-[76px] right-2 sm:left-32' />
                                    <div className='border-[2px] border-primary rounded-[10px] py-2.5 sm:py-[15px] px-[35px] sm:px-[78px] font-extrabold text-[32px] sm:text-[40px] w-full h-[60px] sm:w-[220px] sm:h-[70px] flex justify-center items-center'>
                                        <motion.span>
                                            {rounded}
                                        </motion.span>
                                    </div>
                                    <span className='text-base sm:text-lg text-primary leading-6 tracking-[1%]'>Ажилчид сэтгэл хангалуун хэрэглэж байна</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <motion.div initial={{ opacity: 0, x: -40 }} whileInView={{ opacity: 1, x: 0 }} transition={{ delay: 0.6, duration: 0.6 }} viewport={{ once: true }} className="flex items-start justify-end lg:order-first relative">
                        {tabs.map((tabItem, index) => (
                            <div key={index} className={tabItem.id === tab.id ? "block" : "hidden"}>
                                <img src={tabItem.mobile} className="px-0 w-full max-w-full block sm:hidden" alt={`${tabItem.id}-mobile`} />
                                <img src={tabItem.screen} className="w-full max-w-full hidden sm:block" alt={`${tabItem.id}-screen`} />
                            </div>
                        ))}
                        <div className='flex flex-col justify-center items-center absolute right-2 sm:right-12 lg:right-24 gap-5 xl:gap-[34px] inset-y-[20%]'>
                            { tabs.map((currTab, index) => (
                                <div key={index} className='rounded-lg cursor-pointer' onClick={() => setTab(currTab)}>
                                    <img src={currTab.id === tab.id ? currTab.icon : currTab.dis} alt={currTab.icon} />
                                </div>
                            )) }
                        </div>
                    </motion.div>
                </div>
            </div>
    )
}