import { MonitorCheck } from 'lucide-react'

export default function MobileModal({ setOpen }) {
    return (
        <div>
            <div className="flex gap-2 items-center px-10">
                <MonitorCheck strokeWidth={1.5} className="w-10 h-10" />
                <span className='text-lg sm:text-xl text-modal font-semibold leading-6 sm:leading-7'>Компьютерт зориулагдав</span>
            </div>
            <div className='px-10 mt-8'>
                <div className='text-2xl sm:text-[32px] text-primary leading-7 sm:leading-10 tracking-[2%]'>Уучлаарай, манай систем нь компьютер дэлгэцэнд зориулан бүтээгдсэн<span className='text-accent font-extrabold text-2xl sm:text-[32px] leading-7 sm:leading-10 tracking-[2%]'>.</span></div>
                <p className='mt-[30px] text-base sm:text-lg leading-6 sm:leading-7 text-primary/75 tracking-[1%]'>AmarHR системийн боломжуудыг бүрэн ашиглахын тулд суурин эсвэл зөөврийн компьютерээс дахин зочлоно уу.</p>
                <div className="bg-accent w-[188px] max-w-full h-0.5 my-[30px]"></div>
                <p className='mt-[30px] text-base sm:text-lg leading-6 sm:leading-7 text-primary/75 tracking-[1%]'>Танд баярлалаа.</p>

                <div className="sm:hidden mt-[30px] mb-[14px] w-full flex justify-center rounded-[5px] bg-gradient-to-r from-accent to-[#1a2f8b] px-[68px] py-[7px] text-base sm:text-xl font-bold text-white leading-6 sm:leading-7 shadow-sm hover:bg-accent/90 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent/90" onClick={() => setOpen(false)}>Нүүр хуудас</div>
            </div>
        </div>
    )
}