import { motion } from "framer-motion";
import { ChevronLeft } from 'lucide-react'

import LogoIcon from '../assets/logo-icon.svg'

export default function NotFoundMain() {
    return (
        <div className="px-5 lg:px-[50px] max-w-[1440px] mx-auto pt-[150px] sm:pt-[200px]">
            <motion.div initial={{ opacity: 0, y: -10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.2 }} viewport={{ once: true }} className="flex flex-col items-center justify-center">
                <motion.div initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.6 }} viewport={{ once: true }}>
                    <img src={LogoIcon} alt="Logo" className="w-20 h-20" />
                </motion.div>
                <motion.p initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.7 }} viewport={{ once: true }} className="mt-[15px] sm:mt-[25px] text-[#14153B] text-center text-3xl sm:text-[38px] leading-10 sm:leading-[48px] tracking-[2%]">Уучлаарай<br/>Таны хайсан хуудас олдсонгүй</motion.p>
                <motion.div initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.9 }} viewport={{ once: true }} className="mt-[30px]">
                    <a href='/' className="flex gap-2 items-center">
                        <ChevronLeft className="w-6 h-6" />
                        <span className='text-base sm:text-xl font-semibold leading-6 sm:leading-7 tracking-[1%]'>Буцах</span>
                    </a>
                </motion.div>
            </motion.div>
        </div>
    )
}