import PrivacyMain from "../components/docs/PrivacyMain"
import Header from "../components/layout/Header"
import Footer from "../components/home/Footer"

export default function Privacy() {
    return (
        <div>
            <Header />
            <PrivacyMain />
            <Footer />
        </div>
    )
}