import Header from "../components/layout/Header"
import TermMain from "../components/docs/TermMain"
import Footer from "../components/home/Footer"

export default function Term() {
    return (
        <div>
            <Header />
            <TermMain />
            <Footer />
        </div>
    )
}