import Header from "../components/layout/Header"
import NotFoundMain from "../components/NotFound"

export default function NotFound() {
    return (
        <div>
            <Header />
            <NotFoundMain />
        </div>
    )
}