import { useState } from 'react'
import { toast } from 'sonner'
import { sendAddonRequest } from '../../utils/request'
import { isValidEmail } from '../../utils/util'
    
export default function SystemModal({ setOpen }) {
    const [email, setEmail] = useState('')
    const [question, setQuestion] = useState('')
    const [loading, setLoading] = useState(false)
    const [isButtonDisabled, setIsButtonDisabled] = useState(false)
    const [buttonText, setButtonText] = useState('Илгээх')

    const handleKeyDown = (e) => {
        if(e.key === 'Enter') handleAddonRequest()
    }

    const handleAddonRequest = async () => {
        if(!email) return toast.error('Имэйл хаяг хоосон байна')
        if(!isValidEmail(email)) return toast.error('Имэйл хаяг буруу байна')
        if(!question) return toast.error('Асуулт хоосон байна')
        setLoading(true)
        try {
            const res = await sendAddonRequest(email, question)
            if(!res) throw new Error('Асуултыг илгээхэд алдаа гарлаа')
            toast.success('Асуулт амжилттай илгээгдлээ')
            setIsButtonDisabled(true)
            setButtonText('Илгээгдсэн')
        } catch (error) {
            toast.error('Асуултыг илгээхэд алдаа гарлаа')
        } finally {
            setLoading(false)
        }
    }
    return (
        <div>
            <div className="flex gap-2 items-center px-10">
                <img src="/code.svg" className="w-10 h-10" />
                <span className='text-lg sm:text-xl text-modal font-semibold leading-6 sm:leading-7'>Системийн өөрчлөлт</span>
            </div>
            <div className='px-10 mt-8'>
                <div className='text-2xl sm:text-[32px] text-primary leading-7 sm:leading-10 tracking-[2%]'>Үйл ажиллагааны онцлогтоо тохируулах боломжтой<span className='text-accent font-extrabold text-2xl sm:text-[32px] leading-7 sm:leading-10 tracking-[2%]'>.</span></div>
                <p className='mt-[30px] text-base sm:text-lg leading-6 sm:leading-7 text-primary/75 tracking-[1%]'>+100 ажилтантай байгууллага өөрийн Цаг бүртгэл, Цалин тооцооллын онцлогтоо тохируулан AMARHR-ийг өөрчлүүлэх боломжтой</p>
                <p className='mt-[30px] text-base sm:text-lg leading-6 sm:leading-7 text-primary/75 tracking-[1%]'>Таны хүсэж буй өөрчлөлт боломжтой эсэх хэрэв боломжтой бол хийж гүйцэтгэх <span className='font-semibold text-primary'>хугацаа болон төлбөрийг ярилцаж тохиролцоно</span></p>
                <div className="bg-accent w-[188px] max-w-full h-0.5 my-[30px]"></div>
                <p className='mt-[30px] text-base sm:text-lg leading-6 sm:leading-7 text-primary/75 tracking-[1%]'>Танд системийн өөрчлөлттэй холбоотой асуулт байвал бидэн рүү илгээгээрэй</p>

                <div className="flex flex-col mb-15">
                    <div className="flex flex-col gap-5 w-full mt-[30px]">
                        <div className='w-[350px] max-w-full'>
                            <span className="text-base sm:text-lg leading-6 text-primary/75">
                                Хариулт авах и-мэйл хаяг
                            </span>
                            <div className="mt-1.5">
                                <input id="email" name="email" type="email" value={email} onChange={(e) => setEmail(e.target.value)} autoComplete='email' className="input" />
                            </div>
                        </div>
                        <div>
                            <textarea id="question" name="question" type="text" value={question} onChange={(e) => setQuestion(e.target.value)} onKeyDown={handleKeyDown} placeholder="Асуултаа энд бичнэ үү" className="input" rows={6} />                        
                        </div>
                        <div className='flex justify-end items-end'>
                            { loading ? (
                                <button type="button" className="w-fit flex justify-center rounded-[5px] bg-modal px-[68px] py-[7px] text-base sm:text-xl font-bold text-white leading-6 sm:leading-7 shadow-sm cursor-not-allowed" >
                                    Уншиж байна..
                                </button>
                            ) : (
                                <button type="button" onClick={handleAddonRequest} disabled={isButtonDisabled} className="w-full sm:w-[200px] flex justify-center rounded-[5px] bg-modal px-[68px] py-[7px] text-base sm:text-xl font-bold text-white leading-6 sm:leading-7 shadow-sm hover:bg-modal/90 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-modal/90">
                                    {buttonText}
                                </button>
                            )}
                        </div>
                    </div>

                    <div className="sm:hidden mt-[30px] mb-[14px] w-full flex justify-center rounded-[5px] bg-gradient-to-r from-accent to-[#1a2f8b] px-[68px] py-[7px] text-base sm:text-xl font-bold text-white leading-6 sm:leading-7 shadow-sm hover:bg-accent/90 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent/90" onClick={() => setOpen(false)}>Нүүр хуудас</div>
                </div>
            </div>
        </div>
    )
}