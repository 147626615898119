
import { useState } from 'react'
import { ChevronRight } from 'lucide-react'
import { motion } from 'framer-motion'

import Modal from '../Modal'
import APIModal from './APIModal'
import SystemModal from './SystemModal'

const features = [
    { id: 'help', title: "Системийн тусламж", desc: "Системийн тусламжийг ажлын өдрүүдээр 10:00-18:00 цагийн хооронд системийн чатаар тогтмол өгнө", img: '/sticker4.webp', href: '#' },
    { id: 'api', title: "API холболт", desc: "Дотоод болон гуравдагч программ хангамжтай холбогдоно", img: '/cloud-wired.svg', href: '#' },
    { id: 'system', title: "Системийн өөрчлөлт", desc: "Байгууллагынхаа онцлогт тохируулан өөрчлүүлэх боломжтой", img: '/code.svg', href: '#' },
]

export default function Extra() {
    const [openAPI, setOpenAPI] = useState(false)
    const [openSystem, setOpenSystem] = useState(false)

    const handleModal = (id) => {
        if(id === 'api') {
            setOpenAPI(true)
        }
        if(id === 'system') {
            setOpenSystem(true)
        }
    }

    return (
        <div className="">
            <Modal open={openAPI} setOpen={setOpenAPI}>
                <APIModal setOpen={setOpenAPI}/>
            </Modal>

            <Modal open={openSystem} setOpen={setOpenSystem}>
                <SystemModal setOpen={setOpenSystem}/>
            </Modal>

            <div className="bg-white pt-[175px] pb-[150px] sm:py-[150px] px-5 lg:px-[50px] max-w-[1440px] mx-auto">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[100px] sm:gap-10">
                    { features.map((feature, index) => (
                        <motion.div onClick={() => handleModal(feature.id)} initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.3 * (index + 1), duration: 0.4 }} viewport={{ once: true }} key={index} className='bg-white cursor-pointer sm:pt-5 sm:pb-1.5 flex flex-col justify-between'>
                            <div className='py-[5px] sm:pb-10'>
                                <div className="flex flex-col gap-[5px] justify-center items-start">
                                    { feature.icon && (<feature.icon className="h-10 w-10" />) }
                                    { feature.img && (<img src={feature.img} alt={feature.title} className="h-10 w-10" />) }
                                    <span className="text-base sm:text-lg font-semibold leading-6 sm:leading-7 tracking-[1%]">{feature.title}</span>
                                </div>
                                <div className="mt-5 text-base sm:text-lg text-primary/75 leading-6 sm:leading-7 tracking-[1%] sm:max-w-[366px]">
                                    { feature.desc }
                                </div>
                            </div>
                            { index !== 0 && (
                                <span className="text-lg pt-[25px] sm:pt-0 font-semibold leading-[22px] gap-[5px] tracking-[1%] flex flex-row items-center">
                                    Дэлгэрэнгүй <span aria-hidden="true"><ChevronRight className='w-5 h-5'/></span>
                                </span>
                            )}
                        </motion.div>
                    )) }
                </div>
            </div>
        </div>
    )
}